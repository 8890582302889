import React, { Component } from 'react';
import './App.css';
import One from './components/One';
import { randomBetween } from './utils/utils';

class App extends Component {
  state = {
    images: [],
    loading: true,
  }

  computeNumberOfPagesView() {
    let result = 0;
    for (let i = 0; i < randomBetween(10000000, 100000000); i++) {
      result += i;
    }
    return result;
  }

  weLikeToMoveItMoveIt = () => {
    const { images } = this.state;
    const newImages = images.map((image) => {
      return {
        ...image,
        title: image.title + '!'
      };
    });
    this.setState({ images: newImages });
  }

  componentDidMount() {
      setTimeout(() => {
        fetch('/api/images')
          .then((response) => response.json())
          .then((data) => {
            this.setState({ images: data, loading: false });
          });
      }, 1000);

    setTimeout(() => {
      const delayedElement = document.getElementById('delayed-element');
      if (delayedElement){
        delayedElement.style.display = 'block';
      }
    }, 6000);

    setTimeout(() => {
      const resizingElement = document.getElementById('resizing-element');
      if (resizingElement){
        resizingElement.style.height = '200px';
      }
    }, 7000);
  }


  render() {
    const { images, loading } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    const pagesView = this.computeNumberOfPagesView();


    return (
      <div className="App">
        <h1>Bad Performance App</h1>
        <p>Number of page views: {pagesView}</p>
        <div id="delayed-element" style={{ display: 'none' }}>
          <h2>My personal journal of memories</h2>
        </div>
        <div id="resizing-element" style={{ height: '100px', background: 'lightblue' }}>
          <h2>I love taking beautiful pictures</h2>
        </div>
        <div className="image-list">
          {images.map((image, index) => (
            <One key={Math.random()} image={image} />
          ))}
        </div>
      </div>
    );
  }
}

export default App;

import React from 'react';
import { nanoid } from 'nanoid';

const Three = ({ image }) => {
  return (
    <div className="level-three">
      <h4>Level Three</h4>
      <img src={`${image.url}?r=${nanoid(15)}`} alt={image.title} />
      <p>{image.title}</p>
    </div>
  );
};

export default Three;

import React from 'react';
import Two from './Two';

const One = ({ image }) => {
  return (
    <div className="level-one">
      <h2>Level One</h2>
      <Two image={image} />
    </div>
  );
};

export default One;

import React from 'react';
import Three from './Three';

const Two = ({ image }) => {
  return (
    <div className="level-two">
      <h3>Level Two</h3>
      <Three image={image} />
    </div>
  );
};

export default Two;
